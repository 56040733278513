import React, { useRef, useEffect, useState } from 'react';
import {Button} from '@mui/material'
import { headingLookup } from "../components/commonPage"
import { HeadingKeyHelper, HeadingKeysWithoutExtraField } from "../util/HeadingKeysWithBadgesHelper"
import { navigate } from 'gatsby'
import Searcher from "../components/search"
import FilterTop from "../components/filters"

const exploreMeta = {
    Audience: {
      icon: <i className="fal fa-users"></i>,
      top_focus: 0,
      sub_focus: 0,
    },
    'Product Category': {
      icon: <i className="fal fa-layer-group"></i>,
      top_focus: 1,
      sub_focus: 1,
    },
    Discipline: {
      icon: <i className="fal fa-shapes"></i>,
      top_focus: 2,
      sub_focus: 0,
    },
    'Education Level': {
      icon: <i className="fal fa-signal"></i>,
      top_focus: 0,
      sub_focus: 1,
    },
    'Pricing Model': {
      icon: <div className="pricingIcon"></div>,
      top_focus: 7,
      sub_focus: 1,
    },
  }
  
const ExplorePopup = ({ parent, contentRef, showingCount, searchProducts, setProdObjMapping, order, pageNum,headingFieldNamesLookup,highlightStrMapCallback,exploreChoice, homepage }) => {

    let {filters,extra_validators,extra_spdc_option} = parent.state
    let exploreChoices = Object.keys(exploreMeta)

    let clearFilterFunction = parent.clearAllFilters.bind(parent)
    let displayField = parent.displayField.bind(parent)

    const headingRef:any = useRef(null);
    let jumpFieldName='learningAudience'
    const [jumpLocation, setJumpLocation] = useState(jumpFieldName);

    useEffect(() => {
      // Scroll to the heading when the component mounts
      if (headingRef.current && jumpFieldName!=jumpLocation) {
        headingRef.current.scrollIntoView({ behavior: 'smooth' });
        setJumpLocation(jumpFieldName)
      }
    }, []);

    // headingKeys for filters
    let headingKeys = Object.keys(headingLookup)
    let headingFieldName

    const goToResults = homepage? ()=>{parent.setState({showExploration:false});} : ()=>{navigate('/')}

    if (exploreChoice)
    {
      if (exploreChoice!=99)
      {
        let chosen:any = exploreMeta[exploreChoices[exploreChoice]]
        headingFieldName = headingKeys[chosen.top_focus]
        let filterSection = parent.filterTree[headingFieldName] || []
        let subHeadings = Object.keys( filterSection )
        jumpFieldName = subHeadings[chosen.sub_focus]
        parent.state[jumpFieldName] = true
      }
      else {
        jumpFieldName = "Badges"
        parent.state[jumpFieldName] = true
      }

    }
  
    const HeadingKeysWithExtraFields:HeadingKeyHelper =  new HeadingKeysWithoutExtraField(headingKeys)
    let filterSectionRenders:any = []
    for (let headingFieldName of HeadingKeysWithExtraFields.value())
    {
      let stateName = headingFieldName+'_heading'
      let fieldList = (!!parent.filterTree[headingFieldName])?Object.keys(parent.filterTree[headingFieldName]):[]

      // parent.spdcFilterHelper.setFieldList(fieldList, headingFieldName) //

      filterSectionRenders.push( 
          fieldList.map((fieldName,fieldIndex)=>{
            let meta = parent.filterTree[headingFieldName][fieldName]

            return displayField(meta,'group_'+stateName, (fieldName==jumpFieldName) && headingRef || null )
          })
      )

      // filterSectionRenders.push(<><div>Badges testing</div></>)
    }

    let FilterChip = parent.FilterChip.bind(parent)
    let BadgeChip = parent.BadgeChip.bind(parent)
    let ToggleChip = parent.ToggleChip.bind(parent)

    return <>
          <div id="popup">
          </div>
          <div id="popup_holder" className="filters">
            <div id="popup_window">
              <div className="header">
                Filters
                <i onClick={()=>{parent.setState({showExploration:false})}} className="fal fa-times"></i>
                <Button variant="outlined" onClick={clearFilterFunction}>
                  Clear all filters
                </Button>

                {
                  ((filters.length>0 ) || (extra_validators && extra_validators.length>0) || extra_spdc_option) &&
                  <FilterTop FilterChip={FilterChip} filters={filters} stateWithBadges={parent.state} controller={parent} BadgeChip={BadgeChip} ToggleChip={ToggleChip}></FilterTop>
                }
              </div>
              <div className="filterScrollHolder" ref={contentRef}>
              <div className="validationInside">
              {
                HeadingKeysWithExtraFields.value().map((headingFieldName,headingIndex)=>{
                  let stateName = headingFieldName+'_heading'
                  return <div key={stateName+'_container'} className="section">
                    <h3> {
                        headingLookup[headingFieldName]
                      }</h3>
                      {
                        filterSectionRenders[headingIndex]
                      }
                      {
                        (headingIndex==6) && parent.PrivacyAdditionalItems()
                      }

                      {
                      ///
                      // headingIndex : 0..7
                      (headingIndex==3) && parent.BadgesSideBarSection(null, (exploreChoice==99)? headingRef : null )
                      }
                    </div>
                  })
              }

                <div className="clear"/>
              </div>
              </div>
              <div className="bottomBar">

              <Searcher 
              id="popupsearch" placeholder={"Discovery Starts Here"} variant="outlined"
              onChange={searchProducts}
              setProdObjMapping={setProdObjMapping}
              state={parent.state}
              key="searcher"
              order={order}
              filters={filters}
              pageNum={pageNum}
              filterGroupings={headingFieldNamesLookup}
              setHighlightStrMap={ highlightStrMapCallback }
              legacy={false}
              homepage={true}
              ></Searcher>

              <Button className="template" onClick={goToResults}>Explore {showingCount} Products Now <i className="fal fa-arrow-right"></i></Button>
              <div className="clear"/>
              </div>
            </div>
          </div>
        </>
};

export default ExplorePopup;
