
var _ = require('underscore')

interface HeadingKeyHelper {
    value():string[]
}

class HeadingKeysWithBadgesHelper implements HeadingKeyHelper {
    private _headingKeys: string[];

    constructor(headingKeys: string[]) {
        this._headingKeys = headingKeys;
    }

    private HeadingKeysWithBadgesField() {
        const n = 4
        const kHead = _.clone(_.first(_.clone(this._headingKeys), n))
        const kTail = _.rest(_.clone(this._headingKeys), n)
        return _.flatten([kHead, ["badges"], kTail])
    }

    value():string[]{
        return this.HeadingKeysWithBadgesField()
    }


}


class HeadingKeysWithoutExtraField implements HeadingKeyHelper{
    private _headingKeys: string[];
    constructor(headingKeys: string[]) {
        this._headingKeys = headingKeys;
    }

    value():string[]{
        return this._headingKeys
    }
}

export {HeadingKeyHelper, HeadingKeysWithBadgesHelper, HeadingKeysWithoutExtraField}
