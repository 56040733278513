import React, { useState, useEffect } from 'react';

function setLogoutCookie(logoutUrl)
{
  localStorage.setItem('logoutRedirect', logoutUrl);
}

const UserLogin = ({ loginUrl, logoutUrl, userDetailUrl, setLoggedIn, userData, loadLock }) => {

  if (!userData && !loadLock)
  {
    useEffect(() => {
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(userDetailUrl);
          const data = await response.json();
          //setUser(data);
          if (!!data) setLoggedIn(data)
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
  
      fetchUserDetails();
    }, [userDetailUrl]);
  }
  if (!userData)
  {
    return <div id="login">Loading...</div>;
  }

  if (userData && userData.login) {
    return <div id="login" className="success">My Account
    <div className="widget"><a href='/logout' onClick={()=>{setLogoutCookie(logoutUrl);setLoggedIn({})}}>Logout</a></div>
    </div>;
  }

  return <a id="login" href={loginUrl}>Login</a>;
};

export default UserLogin;
