var _ = require('underscore')

class ChildFiltersHelper{
    private _childFilter: any;

    constructor(childFilterJson) {
        this._childFilter = childFilterJson;

    }

    withoutUnusedProperty(){

    }
}


class MVLookupsForAFieldName{
    private _mvlookupsForAFieldName: any;
    constructor(mvlookupsForAFieldName) {
        this._mvlookupsForAFieldName = mvlookupsForAFieldName;
    }

    getOurLookups(){
        // return this._mvlookupsForAFieldName

        let ans={}
        const unwantedMeta= ['Other LMS not listed', "The product doesn't yet have compatibility with an LMS",'Not applicable to the product',
    
        "Other SIS",
        "The product doesn't yet have compatibility with an SIS",
    
        "Personal email or social account - Education Professional"
        , "Personal email or social account - Parent User"
        , "Personal email or social account - Student User"
        , "Other"
        , "Not applicable"]
        
        for (const [key,vv] of Object.entries(this._mvlookupsForAFieldName)) {
            if ( _.indexOf(  unwantedMeta, key) > -1) continue;
            if ( _.indexOf(  unwantedMeta, vv)  > -1) continue;

            ans[key]=vv
        }
        return ans
    }

}

export {ChildFiltersHelper, MVLookupsForAFieldName}
