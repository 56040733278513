import React, { useState, useEffect } from 'react';

const LoginRequiredPopup = ({ loginUrl, closeFn }) => {

    return <>
    <div id="popup">
    </div>
    <div id="popup_holder" className="login">
      <div id="popup_window">
        <div className="header login">
          Educator Login
          <i onClick={closeFn} className="fal fa-times"></i>
        </div>
        <div className="copy">
            <p>Sign in or create an account to save and compare products on your list.</p>
        </div>
        <a className="button" href={loginUrl}>Sign in</a>
      </div>
    </div>
    </>
};

export default LoginRequiredPopup;
