import React from "react"
var _ = require('underscore')

export default function Filters(props) {

  // here is where the filter chips displaying on products page
    let {filters, FilterChip, stateWithBadges, controller, BadgeChip, ToggleChip} = props

    const badgesDOM= parseBadges(stateWithBadges)

    const returnChips= <div id="applied_filters">
    {
      GenerateFilterChips(filters, FilterChip, stateWithBadges, BadgeChip, ToggleChip)
    }
  </div>

      // const oldRefreshFlip = controller.state.extraFieldRefreshFlip
      

  return returnChips

}

function GenerateFilterChipsForExtraBadges(stateWithBadges: any, BadgeChip: any, ToggleChip:any) {

  let FilterChipForExtraBadges = []
  for (const value of  stateWithBadges.extra_validators) {
    FilterChipForExtraBadges.push(BadgeChip(value))
  }

  if (stateWithBadges['extra_spdc_option']) {
    FilterChipForExtraBadges.push(ToggleChip('SDPC Agreement', 'extra_spdc_option'))
  }
  
  return FilterChipForExtraBadges
  
}

function GenerateFilterChips(filters: any, FilterChip: any, stateWithBadges, BadgeChip, ToggleChip): React.ReactNode {

  let allChips= filters.map((dict, index) => {
    let { value, uniqueId } = dict
    return FilterChip(value, uniqueId)
  })

  const chipsForExtraBadges = GenerateFilterChipsForExtraBadges(stateWithBadges, BadgeChip, ToggleChip)

  const TwoKindOfChips= [...allChips, ...chipsForExtraBadges]
  return TwoKindOfChips
}

function parseBadges(stateWithBadges){

  let {extra_badgesCommonSense, extra_badgesUnicorn, extra_badgesISTESeal, extra_badgesDPResearch, extra_badgesDPLearner} = stateWithBadges

  const BadgesExtra = { extra_badgesCommonSense, extra_badgesUnicorn, extra_badgesISTESeal, extra_badgesDPResearch, extra_badgesDPLearner }

  let ans=[]
  if (extra_badgesCommonSense) ans.push( "Common Sense Privacy Rating" , "extra_badgesCommonSense")

  return ans
}


function badgesMap(){
  return  [
      ["Common Sense Privacy Rating", "badgesCommonSense", "badges_heading"],
      ["Project Unicorn Certification", "badgesUnicorn", "badges_heading"],
      ["ISTE Seal of Alignmemt", "badgesISTESeal", "badges_heading"],
      ["Digital Promise Research-Based Design Certification", "badgesDPResearch", "badges_heading"],
      ["Digital Promise Learner Variability Certification", "badgesDPLearner", "badges_heading"]
    ]
}

function transformMapForExtraBadges() {
  const mapForExtraBadgesPre1 = _.map(badgesMap(), function (x) {
      const k = "extra_" + x[1];
      const v = x[0];
      let ans = {};
      ans[k] = v;
      return ans;
  });

  const mapForExtraBadges = _.reduce(mapForExtraBadgesPre1, function (acc, b) { return _.extend(acc, b); });
  return mapForExtraBadges;
}

